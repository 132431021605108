var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"teble-main-box"},[_c('el-table',{attrs:{"data":_vm.tableData,"stripe":true,"border":true,"header-cell-style":{
            'backgroundColor': '#F5F7FA',
            'color': '#909399',
            'fontSize': '14px',
            'fontWeight': 'normal'
        },"cell-style":{
            fontSize: '14px',
            color: '#262626'
        },"height":"100%"}},[_c('el-table-column',{attrs:{"prop":"WareHouseNames","label":"仓库","align":"center"}}),_c('el-table-column',{attrs:{"prop":"CarrierNames","label":_vm.$t('standard.table3[1]'),"align":"center"}}),_c('el-table-column',{attrs:{"prop":"FreightFee","label":((_vm.$t('standard.table2[1]')) + "(" + _vm.currencySymbol + ")"),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{},[_vm._v(" "+_vm._s(_vm.IsTax(scope.row.ListFreightFeeDetail).FreightFee)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"FreightFeeExpression","label":"详情","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{},[_vm._v(" "+_vm._s(_vm.IsTax(scope.row.ListFreightFeeDetail).FreightFeeExpression)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }