

































import { Component, Vue } from 'vue-property-decorator';

import { GetCalculateTrial } from '@/api/api';
import { otherModule } from '@/store/modules/other';

@Component({
    name: 'EstimationModule',
    components: {}
})
export default class EstimationModule extends Vue {
    public loading: boolean = false;

    public tableData: Array<object> = [];

    public formData: any = {};

    public async open(formData) {
        try {
            this.loading = true;
            this.formData = formData;
            const { Data } = await GetCalculateTrial({
                CountryId: formData.CountryIds.split('|')[1] || '',
                CountryFromId: formData.CountryIds.split('|')[0] || '',
                GoodsTypeId: formData.GoodsTypeId,
                TransportTypeId: formData.TransportTypeId,
                Length: formData.Length || 0,
                Width: formData.Width || 0,
                Height: formData.Height || 0,
                Weight: formData.Weight
            });
            this.tableData = Data;
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public IsTax(arr) {
        // eslint-disable-next-line eqeqeq
        const obj = arr.find((e) => e.IsTax == this.formData.gross);
        return obj || {
            FreightFee: '不支持包税',
            FreightFeeExpression: '不支持包税'
        };
    }

    public get currencySymbol() {
        return otherModule.currencySymbol;
    }
}
